function hasClass_old(el, cls) {
    if (el.className.match('(?:^|\\s)' + cls + '(?!\\S)')) {
        return true;
    }
}

function addClass_old(el, cls) {
    if (!el.className.match('(?:^|\\s)' + cls + '(?!\\S)')) {
        el.className += ' ' + cls;
    }
}

function delClass_old(el, cls) {
    el.className = el.className.replace(new RegExp('(?:^|\\s)' + cls + '(?!\\S)'), '');
}

function elementFromTop(elem, classToAdd, distanceFromTop, unit) {
    var winY = window.innerHeight || document.documentElement.clientHeight,
        elemLength = elem.length, distTop, distPercent, distPixels, distUnit, i;
    for (i = 0; i < elemLength; ++i) {
        distTop = elem[i].getBoundingClientRect().top;
        distPercent = Math.round((distTop / winY) * 100);
        distPixels = Math.round(distTop);
        distUnit = unit == 'percent' ? distPercent : distPixels;
        if (distUnit <= distanceFromTop) {
            if (!hasClass_old(elem[i], classToAdd)) {
                addClass_old(elem[i], classToAdd);
            }
        } else {
            delClass_old(elem[i], classToAdd);
        }
    }
}

const targets = document.querySelectorAll('.row');

const options = {
    rootMargin: '0px',
    threshold: 0.8
};

const addClass = (el) => {
    if (!el.classList.contains('visible')) {
        el.classList.add('visible')
    }
};

const removeClass = (el) => {
    if (el.classList.contains('visible')) {
        el.classList.remove('visible')
    }
};

const doThings = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            addClass(entry.target)
        } else {
            removeClass(entry.target)
        }
    })
};

async function supportsWebp() {
    if (!self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

(async () => {
    if (await supportsWebp()) {
        console.log('does support WebP');
        document.querySelector('html').classList.add('webp');
    } else {
        console.log('does not support WebP');
    }
})();

var ready = (callback) => {
    if (document.readyState !== 'loading') callback();
    else document.addEventListener('DOMContentLoaded', callback);
};

ready(() => {
    inlineSVG.init({
        svgSelector: 'img.svg', // the class attached to all images that should be inlined
        initClass: 'js-inlinesvg', // class added to <html>
    }, function () {
        console.log('All SVGs inlined');
    });

    const header = document.querySelector('.top');
    setTimeout(function () {
        header.classList.add('zoom-complete');
    }, 500);

    setTimeout(function () {
        header.classList.add('transition-complete');
    }, 1500);

    setTimeout(function () {
        document.querySelector('body').classList.add('loaded');
    }, 3000);
});

if (CSS.supports('scroll-snap-align: start')) {
    const observer = new IntersectionObserver(doThings, options);

    targets.forEach(target => {
        observer.observe(target);
    });
} else {
    const bio = document.querySelectorAll('.bio');
    const skills = document.querySelectorAll('.skills');
    const life = document.querySelectorAll('.life');

    window.addEventListener('scroll', function () {
        elementFromTop(bio, 'visible', 0, 'pixels');
        elementFromTop(skills, 'visible', 0, 'pixels');
        elementFromTop(life, 'visible', 0, 'pixels');
    }, false);

    window.addEventListener('resize', function () {
        elementFromTop(bio, 'visible', 0, 'pixels');
        elementFromTop(skills, 'visible', 0, 'pixels');
        elementFromTop(life, 'visible', 0, 'pixels');
    }, false);
}